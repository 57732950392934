import { acceptHMRUpdate, defineStore } from 'pinia'
import { CACHE, clearAllStorageData, getCookie, setCookie } from 'shared'
import { setToken } from 'shared/utils'
import { HOSUrl } from 'shared/utils/constants'

import { AuthService } from '@/services/auth'
import menuService from '@/services/menu'
import { handleMenuAccess } from '@/shared/utils/permission'
import { useMainStore } from '@/stores/main'
import { COMPANY_TYPE_CODE, type AuthState } from '@/types'

const authService = new AuthService()

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    logged: !!getCookie(CACHE.REFRESH_TOKEN, true),
    userInfo: null,
    menus: [],
  }),
  actions: {
    async loginWithUsernameAndPassword(username: string, password: string) {
      const response = await authService.loginWithUsernameAndPassword(username, password)
      return response.data
    },
    async loginWithHosSSO(ssoToken: string) {
      const response = await authService.loginWithHosSSO(ssoToken)
      return response.data
    },
    async loginWithHmmSSO(ssoToken: string) {
      const response = await authService.loginWithHmmSSO(ssoToken)
      return response.data
    },
    async getPasswordPolicy() {
      const response = await authService.passwordPolicy()
      return response.data
    },
    async getResetPassword(emailAddress: string) {
      const response = await authService.getResetPasswordToken(emailAddress)
      return response.data
    },
    async getEnterNewPassword(token: string, newPassword: string) {
      const response = await authService.getEnterNewPassword(token, newPassword)
      return response.data
    },
    async searchID(businessRegistrationNumber: string) {
      const response = await authService.searchID(businessRegistrationNumber)
      return response.data
    },
    async companyProfile(companyCode: string) {
      const response = await authService.companyProfile(companyCode)
      return response.data
    },
    setLogin() {
      this.logged = true
    },
    setUser(userInfo) {
      setToken(userInfo.token)
      const newUserInfo = userInfo
      delete newUserInfo.token
      this.userInfo = newUserInfo
      setCookie(CACHE.USER_INFO, newUserInfo)
    },
    initializeStore() {
      this.initializeUserFromCache()
    },
    initializeUserFromCache() {
      this.userInfo = getCookie(CACHE.USER_INFO) ?? null
    },
    setLogout() {
      const isHOSUser = this.userInfo?.vmsCompanyCode == COMPANY_TYPE_CODE.HOS
      this.logged = false
      this.userInfo = null
      clearAllStorageData()
      useMainStore().resetLayout()
      if (isHOSUser) {
        window.location.href = HOSUrl
      }
    },
  },
  getters: {
    isLogged(state) {
      return state.logged
    },
    getUserInfo(state) {
      return state.userInfo
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
