import { endpoints } from 'shared'
import { isMailAddressFormat } from 'shared/utils'

import { reqGET, reqPATCH, reqPOST } from '@/shared/apis/api-client'

export class AuthService {
  loginWithUsernameAndPassword(username: string, password: string) {
    const requestData = {
      userId: username,
      password,
    }
    return reqPOST(endpoints.common.login, requestData, null, null, false)
  }
  loginWithHosSSO(ssoToken: string) {
    const requestHeaders = {
      'SSO-Token': ssoToken,
    }
    return reqPOST(endpoints.common.loginHOS, null, null, requestHeaders, false)
  }
  loginWithHmmSSO(ssoToken: string) {
    const requestHeaders = {
      'SSO-Token': ssoToken,
    }
    return reqPOST(endpoints.common.loginHMM, null, null, requestHeaders, false)
  }
  passwordPolicy() {
    return reqGET(endpoints.authorization.passwordPolicy, null, null, false)
  }
  getResetPasswordToken(emailAddress: string) {
    return reqPOST(endpoints.authorization.resetPasswordToken, { emailAddress }, null, null, false)
  }
  getEnterNewPassword(token: string, newPassword: string) {
    return reqPOST(
      endpoints.authorization.enterNewPassword,
      { token, newPassword },
      null,
      null,
      false
    )
  }
  searchID(businessRegistrationNumber: string) {
    return reqGET(endpoints.common.searchID(businessRegistrationNumber), null, null, false)
  }
  companyProfile(companyCode: string) {
    return reqGET(endpoints.common.companyProfile(companyCode))
  }
  changePassword(data: { currentPassword: string; newPassword: string; confirmPassword: string }) {
    return reqPOST(endpoints.authorization.changePassword, data)
  }
}
